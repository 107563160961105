



































































































































































import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { ElForm } from 'element-ui/types/form';
import { ElSelect } from 'element-ui/types/select';
import {
  IsUse,
  getDeptData,
  getTenantData,
  getRoleData,
  saveUser,
  editUser,
} from '@/serve/permissionsManage/userManage.ts';
@Component({
  name: 'AddAndEditForm',
})
export default class AddAndEditForm extends Vue {
  @Prop({ type: Function }) getUserData!: Function;
  @Watch('filterText')
  changeText(val: string) {
    (this.$refs.tree as any).filter(val);
  }
  public dialogAddForm = false;
  public form = {
    $lockFlag: '',
    createTime: '',
    deptId: '1',
    tenantId: '',
    deptName: '',
    lockFlag: '',
    password: '',
    phone: '',
    role: [],
    userId: '',
    username: '',
  };
  public deptName = '';
  public rules = {
    username: [
      { required: true, validator: this.validateUserName, trigger: 'blur' },
    ],
    password: [
      { required: true, message: '请输入密码', trigger: 'blur' },
      { min: 3, max: 20, message: '长度需在 3 到 20个字符', trigger: 'blur' },
    ],
    deptId: [{ required: true, message: '请选择所属部门', trigger: 'change' }],
    phone: [{ required: true, message: '长度在11个字符', trigger: 'blur' }],
    role: [{ required: true, message: '请选择角色', trigger: 'change' }],
    lockFlag: [{ required: true, message: '请选择状态', trigger: 'change' }],
  };
  public formLabelWidth = '120px';
  public filterText = '';
  public DeptData = [];
  public TenantData = [];
  public ruleData = [];
  public isAdmin = false;
  public lockFlag = [
    { id: 0, FlagMame: '有效' },
    { id: 1, FlagMame: '锁定' },
  ];
  public IsEdit = false;
  public defaultProps = {
    children: 'children',
    label: 'name',
  };
  public created() {
    this.getDeptData();
    this.getRoleData();
    this.getTenantData();
    const userInfo = JSON.parse(sessionStorage.getItem('userInfo') as string);
    this.isAdmin = userInfo.userId === 1;
  }
  //点击新增用户按钮，触发弹窗
  public addUser() {
    this.dialogAddForm = true;
    this.$nextTick(() => {
      (this.$refs.form as ElForm).resetFields();
      this.form = {
        $lockFlag: '',
        createTime: '',
        deptId: '',
        tenantId: '',
        deptName: '',
        lockFlag: '',
        password: '',
        phone: '',
        role: [],
        userId: '',
        username: '',
      };
      this.deptName = '';
    });
    this.IsEdit = false;
  }
  //自定义校验，判断用户名是否已被注册
  public async validateUserName(rule: any, value: any, callback: any) {
    const res = await IsUse(value);
    // eslint-disable-next-line callback-return
    !res.data ? callback() : callback(new Error('用户名已被注册'));
  }
  //获取部门数据
  public async getDeptData() {
    const res = await getDeptData();
    this.DeptData = res.data;
  }
  //获取租户数据
  public async getTenantData() {
    const res = await getTenantData();
    this.TenantData = res.data.records;
  }
  //点击部门节点，进行赋值id
  public getNodeData(data: { id: string; name: string }) {
    this.form.deptId = data.id;
    this.deptName = data.name;
    //选中后隐藏下拉框
    (this.$refs.select as ElSelect).blur();
  }
  //进行搜索部门节点
  public filterNode(value: any, data: { name: string | any[] }) {
    if (!value) return true;
    return data.name.indexOf(value) !== -1;
  }
  //获取角色数据
  public async getRoleData() {
    const res = await getRoleData();
    this.ruleData = res.data;
  }
  //选中状态，进行赋值$lockFlag状态中文
  public changeFlag(row: { FlagMame: string }) {
    this.form.$lockFlag = row.FlagMame;
  }
  //点击弹窗取消按钮
  public hanlderCancel() {
    (this.$refs.form as ElForm).resetFields();
    this.dialogAddForm = false;
  }
  //点击确认新增用户按钮
  public handlerSubmit() {
    (this.$refs.form as ElForm).validate(async (valid) => {
      if (valid) {
        const res = await saveUser(this.form);
        if (res.data) {
          (this.$refs.form as ElForm).resetFields();
          this.$emit('getUserData');
          this.dialogAddForm = false;
          this.$message({
            message: '新增成功',
            type: 'success',
          });
        }
      } else {
        return false;
      }
    });
  }
  //编辑分割线--------------------------------------
  //点击编辑用户按钮，接受User行数据，触发弹窗
  public editUser(index: number, user: any) {
    this.form = user;
    (this.form['lockFlag'] as any) = Number(user.lockFlag);
    this.$set(this.form, '$index', index);
    this.form.deptId = user.deptId;
    this.deptName = user.deptName;
    const role: number[] = [];
    user.roleList.forEach((ele: { roleId: number }) => {
      role.push(ele.roleId);
    });
    this.$set(this.form, 'role', role);
    this.IsEdit = true;
    this.dialogAddForm = true;
    (this.$refs.form as ElForm).resetFields();
  }
  //编辑保存接口
  public async handlerEdit() {
    (this.$refs.form as ElForm).validate(async (valid) => {
      if (valid) {
        const lockFlag =
          JSON.stringify(this.form.lockFlag) === '0' ? '有效' : '无效';
        this.$set(this.form, '$lockFlag', lockFlag);
        const res = await editUser(this.form);
        if (res.data) {
          (this.$refs.form as ElForm).resetFields();
          this.$emit('getUserData');
          this.dialogAddForm = false;
          this.$message({
            message: '编辑成功',
            type: 'success',
          });
        }
      }
    });
  }
  //清空表单验证和数据
  public closeDialog() {
    (this.$refs.form as ElForm).resetFields();
  }
}
