import request from '../../utils/request'

interface Page {
  current: string | number;
  size: string | number;
  username?: string;
}
//获取用户表admin/user/details
export function getUserData(data: Page): Promise<any> {
  return request({
    method: 'GET',
    url: 'admin/user/page',
    type: 'params',
    data: data
  })
}
//判断该用户名是否有被注册admin/dept/tree
export function IsUse(data: number | string): Promise<any> {
  return request({
    method: 'GET',
    url: 'admin/user/details/' + data,
  })
}
//获取部门列表admin/role/list
export function getDeptData(): Promise<any> {
  return request({
    method: 'GET',
    url: 'admin/dept/tree'
  })
}
//获取租户列表
export function getTenantData(): Promise<any> {
  return request({
    method: 'GET',
    url: 'manage/tenant/list?current=1&size=100000'
  })
}
//获取角色列表admin/user
export function getRoleData(): Promise<any> {
  return request({
    method: 'GET',
    url: 'admin/role/list'
  })
}
interface Form<T> {
  $lockFlag: T;
  createTime: T;
  deptId: T;
  deptName: T;
  lockFlag: number | string;
  password: T;
  phone: T;
  role: any[];
  userId: T;
  username: T;
}
//新增用户表
export function saveUser(form: Form<string>): Promise<any> {
  return request({
    method: 'POST',
    url: 'admin/user',
    data: form
  })
}
//编辑用户表
export function editUser(form: Form<string>): Promise<any> {
  return request({
    method: 'PUT',
    url: 'admin/user',
    data: form
  })
}
//删除用户表
export function deleteUser(userId: string): Promise<any> {
  return request({
    method: 'DELETE',
    url: 'admin/user/' + userId,
  })
}